import React from 'react';
import { LoadScript } from '@react-google-maps/api';


// exports.onRouteUpdate = ({ location }) => getAnchor(location)

// /**
//  *
//  * @desc - a function to jump to the correct scroll position
//  * @param {Object} location -
//  * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
//  */
// function getAnchor(location) {
//   
//   // Check for location so build does not fail
//   //   if (location && location.hash) {
//   //     console.info('location ok');

//   //     
//   //     
//   //     // const item = document.querySelector(`${location.hash}`).offsetTop

//   //     // window.scrollTo({
//   //     //   top: item - mainNavHeight,
//   //     //   behavior: "smooth",
//   //     // })
//   //   }
//   //   else{
//   //       
//   //   }

//   return true
// }

export const wrapRootElement = ({ element }) => (

  <LoadScript
    googleMapsApiKey="AIzaSyBUnyUAnVWrNulU230PLq5aWFoDKK29Bvg"
  // loadingElement={<Center minH='90vh' p={12}><Spinner size='sm' color='brand.blue' /></Center>}
  >

    {element}

  </LoadScript>
)